<template>
    <el-select v-model="selected" filterable remote reserve-keyword clearable placeholder="请输入关键词"
        :remote-method="search" :loading="loading" value-key='id'>
        <el-option v-for="item in users" :key="item.id" :label="item.name" :value="item">
        </el-option>
    </el-select>
</template>

<script>
    import { ref } from 'vue';
    import { searchUserLikeName } from '@/utils/api'

    export default {
        setup() {
            let users = ref([])
            let loading = ref(false)
            function search(query) {
                if (query !== '') {
                    loading.value = true;
                    searchUserLikeName({ name: query }).then(res => {
                        // 更新结果列表
                        loading.value = false;
                        users.value = res.data
                    })
                } else {
                    users.value = [];
                }
            }
            return {
                users,
                selected: ref(),
                loading: false,
                search,
            }
        },
    }
</script>