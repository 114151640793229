<template>
    <div>
        <el-button type="primary" circle size="mini" icon='el-icon-plus' @click="show = true; input = ''"></el-button>
        <el-dialog :title="title" v-model="show" width="320px">
            <UserSearchInput v-model="selected"></UserSearchInput>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="show = false">关 闭</el-button>
                    <el-button v-if='selected != undefined' @click="confirm">确定</el-button>
                </span>
            </template>
        </el-dialog>

    </div>
</template>
<style>
</style>
<script>
    import { ref } from 'vue'
    import UserSearchInput from '@/components/UserSearchInput'
    import { searchUserLikeName } from '@/utils/api'
    export default {
        components: { UserSearchInput },
        emits: ["confirm"],
        props: ['title'],
        setup(props, { emit }) {
            let show = ref(false)
            let selected = ref(undefined)
            function confirm() {
                if (selected.value) {
                    emit('confirm', selected.value)
                }
                show.value = false
            }
            return {
                show,
                selected,
                confirm,
            }
        }
    }
</script>