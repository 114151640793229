<template>
    <UserSearch title="添加管理员" @confirm='add'></UserSearch>
    <el-table :data="ownerList" stripe border highlight-current-row style="width: 100%">
        <el-table-column prop="userId" label="用户ID" show-overflow-tooltip width="240"></el-table-column>
        <el-table-column prop="name" label="姓名" show-overflow-tooltip></el-table-column>
        <el-table-column prop="base.created" show-overflow-tooltip width="180" label="创建时间"></el-table-column>
        <el-table-column prop="base.creatorName" show-overflow-tooltip width="100" label="创建人"></el-table-column>
        <el-table-column prop="base.updated" show-overflow-tooltip width="180" label="更新时间"></el-table-column>
        <el-table-column prop="base.updaterName" show-overflow-tooltip width="100" label="更新人"></el-table-column>
        <el-table-column prop="base.updaterName" show-overflow-tooltip label="操作" width="100">
            <template #default="scope">
                <el-popconfirm title='确认删除？' confirmButtonText='删除' cancelButtonText='取消' @confirm='remove(scope.row)'>
                    <template #reference>
                        <el-button icon='el-icon-circle-close' type="text" class="button"> </el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
    import { ownerSearch, ownerAdd, ownerRemove } from '@/utils/api'
    import { getPathVariableAppKey } from '@/utils/tools'
    import { ref } from 'vue'
    import UserSearch from '@/components/UserSearch'

    export default {
        components: { UserSearch },
        setup() {
            const appkey = getPathVariableAppKey()
            let ownerList = ref([])

            //搜索应用管理员
            function search() {
                ownerSearch(appkey).then(res => {
                    ownerList.value = res.data
                })
            }
            // 删除应用管理员
            function remove(row) {
                if (row) {
                    let data = { ids: [row.id] }
                    ownerRemove(appkey, data).then(res => {
                        search()
                    })
                }
            }
            // 新增应用管理员
            function add(user) {
                let param = [{
                    name: user.name,
                    userId: user.id
                }]
                ownerAdd(appkey, param).then(res => {
                    search()
                })
            }
            return {
                ownerList,
                search,
                remove,
                add,
            }

        },
        mounted() {
            this.search()
        }
    }
</script>